* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Optional: prevent scrollbars if not needed */
  font-family: "Bangers", system-ui;
  font-weight: 400;
  font-style: normal;
}
